import {
	faBars,
	faEnvelope,
	faFilePdf,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import resumePDF from "./documents/Aaron Koenes Resume.pdf";

export function Content(props) {
	return (
		<div id={"content"}>
			<ExperienceSelector />
			<Intro />
			<Skills />
			<Experience />
			<Contact />
			<References />
			<About />
		</div>
	);
}

export function MobileHeader() {
	useEffect(() => {
		const toggleNavigation = () => {
			const navigationElement = document.getElementById("navigation");
			navigationElement.classList.toggle("active");
		};

		const closeNavigation = (event) => {
			const navigationElement = document.getElementById("navigation");
			const mobileHeaderElement = document.getElementById("mobileheader");

			if (mobileHeaderElement.contains(event.target)) {
				return; // If the click is within the MobileHeader, do not close the navigation menu
			}

			if (
				navigationElement.classList.contains("active") &&
				!navigationElement.contains(event.target)
			) {
				navigationElement.classList.remove("active");
			}
		};

		const faBarsElement = document.querySelector("#mobileheader .fa-bars");
		faBarsElement.addEventListener("click", toggleNavigation);
		document.addEventListener("click", closeNavigation);

		// Clean up function to remove the event listener when the component unmounts
		return () => {
			faBarsElement.removeEventListener("click", toggleNavigation);
			document.removeEventListener("click", closeNavigation);
		};
	}, []);

	return (
		<div id={"mobileheader"}>
			<FontAwesomeIcon icon={faBars} />
		</div>
	);
}

// Create an Experience Selector element which appears above the ocntent but below the header
// Make it a dropdown list with the following options: Netsuite Developer and IT Manager
export function ExperienceSelector() {
	return (
		<div id={"experienceSelector"}>
			<select>
				<option value={"Netsuite Developer"}>Netsuite Developer</option>
				<option value={"IT Manager"}>IT Manager</option>
			</select>
		</div>
	);
}

function Intro() {
	return (
		<div className={"section"} id={"intro"}>
			<div className={"section-data"} id={"intro-data"}>
				<h2>Aaron Koenes</h2>
				<div className={"section-content"}>
					<div>
						I am a manager and developer with decades of expertise in software
						development, as well as years of experience managing IT projects and
						teams. I have a penchant for learning and can pick up new skills
						quickly. I love discovering how things work and finding ways to
						improve them.
					</div>
					<div id={"resumeLink"}>
						<a href={resumePDF} target={"_blank"} rel="noreferrer">
							<FontAwesomeIcon icon={faFilePdf} />
							View my resume as a PDF
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

function Skills() {
	const skills = [
		{
			title: "Team Management",
			description: `Since 2018, I have managed a growing team of developers with a wide array of focuses. My team and I have designed and built web apps used to run our warehouse, identified and implemented critical updates to our ERP, developed and documented integrations with external systems, and set up security procedures to protect our company's data. These tasks were completed successfully with me at their lead.`,
		},
		{
			title: "Task Management",
			description:
				"A critical component of my current work is managing the countless tasks that are requested of me and my team. I constantly considered ways for me and my team to improve the way that we handle big tasks and small, and I understand the importance of knowing when task must take precedence over others.",
		},
		{
			title: "Javascript Development",
			description: `I started teaching myself Javascript early in my time at college. Since then, I've made sure to keep up on Javascript's improvements and continued to practice them to improve my skills.`,
		},
		{
			title: "Netsuite Administration and Development",
			description: `I began working with Netsuite in early 2011 to deploy it at my current employer. Since then, I've become an expert in both its administration and development. I have a very wide range of understanding of what Netsuite offers and over a decade of experience in molding it to fit our business' needs. I have a very deep of its script language and in Netsuite administration through the UI.`,
		},
		{
			title: "DevOps",
			description: `The work that I've both managed for my team and specifically accomplished goes beyond just writing code. That code must fit into the processes used by others, developed and tested, and then supported and improved. I have years of experience with this sort of work and have developed the skills needed to understand large-picture goals.`,
		},
		{
			title: "Programming",
			description: `My career in programming began with Java back in 2004. It was then that I was first exposed to the purely logical nature of programming and it just seemed to "click" in my mind. Since then, I've only grown to enjoy it more and more. I find great joy in learning of ways to expand my coding skill set and then implementing what I've learned and seeing those improvements in action.`,
		},
		{
			title: "Git",
			description: `I've had several years of experience with Git and find it to be a remarkably useful tool. I enjoy learning about all the features it implements and how easily (usually) it allows you to manage your code. I have a good understanding of how to use Git in general and some experience with using it directly in the CLI.`,
		},
		{
			title: "Communication",
			description: `I've honed my communication skills throughout my time at the Tekton, developing the ability to effectively interact with colleagues at all levels. I’ve mastered writing emails with clear requests, requirements, and goals, and I’m adept at explaining complex concepts to people with varying degrees of experience in the topics I'm discussing with them. I'm also skilled in taking notes in real-time as discussions are taking place so that we can better understand what decisions were made and why we made them.`,
		},
	];

	return (
		<div className={"section"} id={"skills"}>
			<div className={"section-data"} id={"skills-data"}>
				<h2>Skills</h2>
				<div className={"section-content"}>
					<div>
						<ul>
							{skills.map((skill) => (
								<Skill
									{...skill}
									key={skill.title.toLowerCase().replace(/[^a-z]/, "")}
								/>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

function Skill(props) {
	return (
		<li>
			<h3 className={"skillTitle"}>{props.title}</h3>
			<div className={"skillDescription"}>{props.description}</div>
		</li>
	);
}

function Experience() {
	const positions = [
		{
			key: "operationsitmanager",
			title: "Operations IT Manager",
			company: "Tekton, Inc",
			date: "Jan 2018 - Present",
			responsibilities: [
				"Led a team of developers in a fast-paced environment.",
				"Gave guidance and direction to team members for individual and group tasks.",
				"Implemented best-practices for IT security, including password management, security policy enforcement, and phishing training.",
				"Assisted several departments in the deployment an enterprise cloud WMS system, of which the IT aspects were the single most stable areas after deployment.",
				"Successfully completed countless complex DevOps projects, including gathering requirements, designing project plans, participating in development, and giving support.",
				"Honed my communication skills in order to write clear emails, explain concepts eloquently, and take details notes about current discussions",
				"Planned and implemented IT backup and recovery strategies.",
				"Documented and deployed stable multiple integrations with our WMS, website, and a custom web application used internally by our product assembly teams.",
				"Interviewed prospective hires and held performance assessments with my team.",
				"Organized and monitored progress on team projects and prioritized team tasks.",
				"Negotiated pricing for services used throughout the company",
			],
		},
		{
			key: "informationsystemsdeveloper",
			title: "Information Systems Developer",
			company: "Tekton, Inc",
			date: "May 2008 - Dec 2017",
			responsibilities: [
				"Developed a CRM on modern software to replace an aging DOS-based CRM.",
				"After using the CRM successfully for several years, worked with a small team to replace it with the enterprise-grade, web-based CRM NetSuite.",
				"Learned NetSuite's Javascript APls to design enhancements to its front-end and back-end functionality.",
				"Located, diagnosed, troubleshot, and corrected bugs and performance issues within Netsuite.",
				"Designed, built, and deployed a label-printing system in our warehouse using Node.js, Raspberry Pis, and bash scripts.",
				"Worked in a small team to build and deploy a custom warehouse management system.",
				"Assisted coworkers in diagnosing and resolving issues with their IT hardware.",
			],
		},
	];

	return (
		<div className={"section"} id={"experience"}>
			<div className={"section-data"} id={"experience-data"}>
				<h2>Experience</h2>
				<div className={"section-content"}>
					{positions.map((p) => (
						<Position
							title={p.title}
							company={p.company}
							date={p.date}
							responsibilities={p.responsibilities}
							respKey={p.key}
							key={p.key}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

function References() {
	return <></>;
}

function Contact() {
	setTimeout(() => {
		const primaryEmailEl = document.getElementById("primaryContactEmail");
		const secondaryEmailEl = document.getElementById("secondaryContactEmail");
		const phoneEl = document.getElementById("contactPhone");

		primaryEmailEl.innerText = "aaron.koenes";
		primaryEmailEl.innerText += "@";
		primaryEmailEl.innerText += "gmail.com";

		secondaryEmailEl.innerText = "A";
		secondaryEmailEl.innerText += "@";
		secondaryEmailEl.innerText += "ronkoen.es";

		phoneEl.innerText = "(616) 890 9539";
	}, 500);

	return (
		<div className={"section"} id={"contact"}>
			<div className={"section-data"} id={"contact-data"}>
				<h2>Contact</h2>
				<noscript>
					<div className={"warning"}>
						&#9888; This section uses javascript to avoid scraping. The contact
						information you see here won't allow you to contact me. Please
						enable javascript to see my contact information.
					</div>
				</noscript>
				<div className={"section-content"}>
					<p>
						I currently live near Grand Rapids, Michigan with my wife and son.
						At the moment, I am primarily interested in full-time remote work.
						If you believe that I would be a good fit for your team, please
						reach out to me.
					</p>
					<div>
						<p>
							<FontAwesomeIcon icon={faEnvelope} />{" "}
							<span id={"primaryContactEmail"}>
								brisk.flame8321@fastmail.com
							</span>
						</p>
						<p>
							<FontAwesomeIcon icon={faEnvelope} />{" "}
							<span id={"secondaryContactEmail"}>
								brisk.flame8321@fastmail.com
							</span>
						</p>
						<p>
							<FontAwesomeIcon icon={faPhone} />{" "}
							<span id={"contactPhone"}>(555) 867-5309</span>
						</p>
						<p id={"phoneNote"}>
							Please note that I generally don't accept unexpected calls from
							unknown numbers.
							<br />
							Email or text is the best way to initiate communication.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

function About() {
	const ageCalc = (date1, date2) => {
		const years = date2.getFullYear() - date1.getFullYear();
		const months = date2.getMonth() - date1.getMonth();
		const days = date2.getDate() - date1.getDate();

		let monthDiff = years * 12 + months;

		monthDiff += days > 15;

		if (monthDiff < 12) {
			return `${monthDiff}-month`;
		} else {
			const yearDiff = Math.floor(monthDiff / 12);
			return `${yearDiff}-year`;
		}
	};

	return (
		<div className={"section"} id={"about"}>
			<div className={"section-data"} id={"about-data"}>
				<h2>About me</h2>
				<div className={"section-content"}>
					<div>
						<p>
							I live in western Michigan with my wife, Jennifer, and our{" "}
							{ageCalc(new Date("12/9/2023"), new Date())}-old son, Micah.
						</p>

						<p>
							I fill my free time with a number of hobbies. I often find myself
							programming for my own enjoyment. I love learning about
							advancements in languages that I'm experienced with, such as
							Javascript, but also find great satisfaction in journeying into
							the unknown. I've dabbled in Rust, which has been a fascinating
							experience, and have spent time on and off for years making sites
							like this one, which let me touch on what I know while also trying
							out technologies that are new to me. I also have fun improving my
							smart home setup and figuring out what automations I can set up to
							help improve the lives of everyone in my family.
						</p>

						<p>
							My wife and I enjoy salsa dancing. I've taken some lessons, but
							can't yet compare to her skill as she grew up in a Cuban family
							where salsa was common. I very much enjoy it, though, and always
							look forward to learning new moves.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

// An "Experience" element which which will take in a job title, company, and date range, as well as a list of responsibilities and accomplishments
export function Position(props) {
	return (
		<div className={"experience-element"}>
			<h3>{props.title}</h3>
			<div>{props.company}</div>
			<div>{props.date}</div>
			<ul>
				{props.responsibilities.map((r, index) => (
					<li key={`${props.respKey}-responsibility-${index}`}>{r}</li>
				))}
			</ul>
		</div>
	);
}
