import {
	faBriefcase,
	faEnvelope,
	faInfo,
	faTools,
	faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

function Navigation(props) {
	useEffect(() => {
		const navigationElement = document.getElementById("navigation");
		const sectionLinks = document.querySelectorAll("#navigation a");

		const hideNavigationOnMobile = () => {
			if (window.innerWidth <= 600) {
				navigationElement.classList.remove("active");
			}
		};

		for (const link of sectionLinks) {
			link.addEventListener("click", hideNavigationOnMobile);
		}

		// Clean up function to remove the event listeners when the component unmounts
		return () => {
			sectionLinks.forEach((link) => {
				link.removeEventListener("click", hideNavigationOnMobile);
			});
		};
	}, []);

	return (
		<div id="navigation">
			<NavElement icon={faUserAlt} title={"Aaron Koenes"} link={"intro"} />
			<NavElement icon={faTools} title={"Skills"} link={"skills"} />
			<NavElement icon={faBriefcase} title={"Experience"} link={"experience"} />
			<NavElement icon={faEnvelope} title={"Contact"} link={"contact"} />
			{/*<NavElement icon={faQuoteRight} title={'References'} link={'references'} />*/}
			<NavElement icon={faInfo} title={"About"} link={"about"} />
		</div>
	);
}

function NavElement(props) {
	return (
		<a href={`#${props.link}`} className={"nav-element"}>
			<span>
				<FontAwesomeIcon icon={props.icon} />
			</span>
			<h3>{props.title}</h3>
		</a>
	);
}

export default Navigation;
