import "./App.css";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Content, MobileHeader } from "./Content";
import Navigation from "./Navigation";

ReactGA.initialize("G-302KRW5TKL");

function App() {
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname });
	}, []);

	return (
		<div className="App">
			<MobileHeader />
			<Navigation />
			<Content />
		</div>
	);
}
export default App;
